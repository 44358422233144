import { Context } from '@nuxt/types';

import { userModule } from '@/utils/store-accessor';

/**
 * If the user is not authenticated, redirect them to the login page
 * and then send them back to their previous page after logging in
 */
export default async ({ redirect, route }: Context) => {
  if (!userModule.isAuthenticated) {
    if (await userModule.silentAuthenticate()) {
      return;
    }

    const destination = route.fullPath;
    return redirect('/login', { destination });
  }
};

export const authGuard = 'authGuard';
