/* eslint-disable-next-line -- typesItem  */
import FacetDropdownItem from '@/components/jobs/facets/types/FacetDropdownItem';
/* eslint-disable-next-line -- typesItem  */
import DropdownSelectItem from '@/components/controls/types/DropdownSelectItem';

import {
  Category,
  Experience,
  ExperiencesList,
  Industry,
  Job,
  JobDto,
  RemoteOnly,
  Subcategory,
  SubcategoryRelation,
  TargetedRemoteLocation,
} from '@/store/jobs/types';
import { Region, Location } from '@/store/types';
import { City, SelectedExpertise } from '@/store/onboarding/types';
import { DefaultJobsApiSorting, SortNameHotJobs } from '@/api/types';
import { ICompany, IJob, IJobTargetedRemoteLocations } from '@/typings/api/external-api-gql';

import constants from '@/enums/constants';
import regions, { getCurrentRegionID } from '@/api/regions';

/**
 * @returns true if the user has selected Remote jobs
 */
export const isRemoteSelected = (remoteSelections: number[]) => {
  return remoteSelections.includes(constants.HYBRID_JOBS_TYPES_JRS.REMOTE);
};

/**
 * @returns true if the user has selected Hybrid or In-Office and Remote jobs
 */
export const isHybridSelected = (remoteSelections: number[]) => {
  return remoteSelections.includes(constants.HYBRID_JOBS_TYPES_JRS.HYBRID);
};

/**
 * @returns true if the user has selected In Office jobs
 */
export const isInOfficeSelected = (remoteSelections: number[]) => {
  return remoteSelections.includes(constants.HYBRID_JOBS_TYPES_JRS.IN_OFFICE);
};

/**
 * converts user-selected regions on the job board to a single line name
 */
export const remoteFilterJobLabel = (
  activeRegionDisplay: string[],
  limitLabelToShow = 2
): string => {
  const isNational = regions.isNational();

  const activeRegion =
    activeRegionDisplay.length > limitLabelToShow && isNational
      ? activeRegionDisplay.slice(0, limitLabelToShow)
      : activeRegionDisplay;

  return activeRegion.join(', ');
};

/**
 * Filters out South Suburbs because we do not have jobs for that location.
 * Filters out Chicago only on national because we do not import jobs for that location.
 *
 * @returns a filter function to apply for the Chicago region
 */
const chicagoSubLocationFilter = () =>
  regions.isNational()
    ? (item: Location) => item.name !== 'South Suburbs' && item.name !== 'Chicago'
    : (item: Location) => item.name !== 'South Suburbs';

export const facetLocationFilter = (region: Region) => {
  if (!region.locations) {
    return [];
  }

  let locations = [...region.locations];
  // Remove sub-locations with 0 jobs
  switch (region.id) {
    // Austin
    case 4:
      locations = locations.filter((item: Location) => item.name !== 'Suburbs');
      break;
    // Boston
    case 6:
      locations = locations.filter((item: Location) => item.name !== 'Brookline');
      break;
    // Chicago
    case 1:
      locations = locations.filter(chicagoSubLocationFilter());
      break;
    // NYC
    case 5:
      locations = locations
        .filter((item: Location) => item.name !== 'Bronx')
        .filter((item: Location) => item.name !== 'Brooklyn')
        .filter((item: Location) => item.name !== 'Manhattan')
        .filter((item: Location) => item.name !== 'Queens')
        .filter((item: Location) => item.name !== 'Staten Island');
      break;
    // San Francisco
    case 8:
      locations = locations.filter((item: Location) => item.name !== 'North Bay');
      break;
  }
  // Remove "Remote" item
  return locations.filter((item: Location) => item.name !== 'Remote');
};

//filter FacetItem(Regions and location) by term
export const searchOptionsByTerm = (options: FacetDropdownItem[], searchTerm: string) => {
  const userTypeIn = searchTerm.toLocaleLowerCase();
  return options.filter((option: FacetDropdownItem) => {
    const title = option.name.toLocaleLowerCase();
    return title.includes(userTypeIn);
  });
};

/**
 * map levels from the expertise section on profile/edit page  to job board Experience level or JRS request params
 *
 * @param expertise
 */

export const mapProfileExperienceLevelToJob = (expertise: SelectedExpertise): Experience | null => {
  const level = expertise.level;
  const [entryLevel, midLevel, seniorLevel, notSpecified] = ExperiencesList;
  switch (level) {
    case '1':
      return entryLevel;
    case '1-3':
    case '3-5':
      return midLevel;
    case '5-7':
    case '7+':
      return seniorLevel;
    case '0':
      return notSpecified;
    default:
      return null;
  }
};

/**
 * @returns Mapping of remote, in-office, and hybrid data
 */
export const getRemoteMap = () => {
  return [
    {
      name: 'In Office',
      alias: 'office',
      value: constants.HYBRID_JOBS_TYPES_JRS.IN_OFFICE,
    },
    {
      name: 'Remote',
      alias: 'remote',
      value: constants.HYBRID_JOBS_TYPES_JRS.REMOTE,
    },
    {
      name: 'Hybrid',
      alias: 'hybrid',
      value: constants.HYBRID_JOBS_TYPES_JRS.HYBRID,
    },
  ];
};

/**
 * Handles all logic associated to mapping remote string alias to id (including edge-case logic for national vs market).
 * @param subPaths array of sub paths from splitting a path string
 * @returns Array of remote selections
 */
export const getRemoteStatus = (subPaths: string[]): number[] => {
  const remoteMap = getRemoteMap();

  const matches = remoteMap.filter((status) => {
    return subPaths.includes(status.alias);
  });

  return matches.map((match) => match.value);
};

/**
 * Breaks down a jobs url path into a series of job filter objects (category, regions, experience, etc.)
 * @param href jobs url path
 * @param filters list of all available filters categorized by type
 * @returns a categorized object of filters based on their urlAlias -> category type mapping
 */
export const getRouteCriterias = (
  href: string,
  filters: {
    categoriesList: Category[];
    subcategoryList: SubcategoryRelation;
    experienceList: Experience[];
    industriesList: Industry[];
    companySizeList: DropdownSelectItem[];
    regionsList: Region[];
  }
) => {
  const [path, params] = href.split('?');
  const routeQuery = new URLSearchParams(params);
  const {
    categoriesList,
    subcategoryList,
    experienceList,
    industriesList,
    companySizeList,
    regionsList,
  } = filters;

  //Process a variety of query parameters + global variables
  const page = parseInt(routeQuery.get('page') || '1');
  const search = decodeURIComponent(routeQuery.get('search') || '');
  const sorting = routeQuery.has('trending') ? SortNameHotJobs : DefaultJobsApiSorting;

  // Short circuit if we're at the root "/jobs" path.
  if (!path) {
    return {
      categories: [],
      subCategories: [],
      experiences: [],
      industries: [],
      companySize: [],
      regions: [],
      locations: [],
      remoteStatus: [],
      page,
      search,
      sorting,
    };
  }

  // Categorize each subpath into their appropriate job filter (category, subcategory, region, etc.)
  const subPaths = path.split('/');
  const remoteStatus = getRemoteStatus(subPaths);
  const categories = categoriesList.filter((c) => subPaths.includes(c.alias));

  let subCategories = [] as Subcategory[];
  for (let c of categories) {
    if (subcategoryList[c.id]) {
      subCategories = subcategoryList[c.id].filter((s: Subcategory) => subPaths.includes(s.alias));
    }
  }

  const experiences = experienceList.filter(({ alias }) => subPaths.includes(alias));
  const industries = industriesList.filter(({ alias }) => subPaths.includes(alias));
  const companySize = companySizeList.filter(({ value }) => subPaths.includes(value));
  const regions = regionsList.filter(({ alias }) => subPaths.includes(alias));

  let locations = [] as Location[];
  for (let r of regions) {
    const includeLocation = r.locations.filter(
      ({ alias }) => subPaths.includes(alias) && alias !== 'remote'
    );
    locations = locations.concat(includeLocation);
  }

  return {
    categories,
    subCategories,
    experiences,
    industries,
    companySize,
    regions,
    locations,
    remoteStatus,
    page,
    search,
    sorting,
  };
};

/**
 * Convert user selected location and regions to TargetedRemoteLocations
 *
 * @param regions
 * @param locations
 */
export const flatRegionsLocations = (
  regions: Region[],
  locations: Location[]
): TargetedRemoteLocation[] => {
  const regionsMap = regions.map((region) => ({ region_id: region.id, location_id: 0 }));
  const locationsMap = locations.map((location) => ({
    region_id: location.region_id,
    location_id: location.id,
  }));

  return [...new Set([...regionsMap, ...locationsMap])]; // Use a `Set` to remove any duplicates
};

export const getTargetLocationName = (
  regions: Region[],
  targetLocation: TargetedRemoteLocation
): string | undefined => {
  const regionData = regions.find((region) => region.id === targetLocation.region_id);
  if (!regionData) {
    console.error('271: getTargetLocationName: region not found');
    return;
  }

  // is region
  if (targetLocation.location_id === 0) {
    return regionData?.name;
  }

  if (targetLocation.location_id > 0 && targetLocation.region_id > 0) {
    const location: Location | undefined = regionData.locations.find(
      (location: Location) => location.id === targetLocation.location_id
    );

    return location?.name;
  }

  return;
};

/**
 * Returns a list of target locations based on activeLocations and activeRegions.
 * @param activeLocations
 * @param activeRegions
 */
export const jobTargetLocation = (activeLocations: Location[], activeRegions: Region[]) => {
  const targetLocations: string[] = [];

  activeLocations.forEach((location) => {
    targetLocations.push(`${location.region_id}|${location.id}`);
  });
  activeRegions.forEach((userRegionSelected) => {
    const hasActiveLocations = activeLocations.some((l) => l.region_id === userRegionSelected.id);

    const hasChildLocations = userRegionSelected.locations.length;

    if (!hasActiveLocations && hasChildLocations) {
      userRegionSelected.locations.forEach((location) => {
        targetLocations.push(`${userRegionSelected.id}|${location.id}`);
      });
    }

    if (!hasActiveLocations) {
      targetLocations.push(`${userRegionSelected.id}|0`);
    }
  });
  return targetLocations;
};

// TODO refactor remove City interface https://jira.builtin.com/browse/PODC-2544
// TODO remove function, duplicate of jobTargetLocation function
/**
 *
 * @deprecated
 * @param activeLocations
 * @param activeRegions
 */
export const jobTargetLocationCityInterface = (
  activeLocations: Location[],
  activeRegions: City[]
) => {
  const targetLocations: string[] = [];

  activeLocations.forEach((location) => {
    targetLocations.push(`${location.region_id}|${location.id}`);
  });
  activeRegions.forEach((userRegionSelected) => {
    const hasActiveLocations = activeLocations.some((l) => l.region_id === userRegionSelected.id);

    const hasChildLocations = userRegionSelected.locations.length;

    if (!hasActiveLocations && hasChildLocations) {
      userRegionSelected.locations.forEach((location) => {
        targetLocations.push(`${userRegionSelected.id}|${location.location_id}`);
      });
    }

    if (!hasActiveLocations) {
      targetLocations.push(`${userRegionSelected.id}|0`);
    }
  });
  return targetLocations;
};
/**
 * Convert IJob interface to Job interface
 * @param jobs
 */
export const mapIJobToJobInterface = (jobs: IJob[]): Partial<Job>[] => {
  return jobs.map((ijob) => {
    // @ts-ignore
    const targeted_remote_locations: IJobTargetedRemoteLocations[] =
      ijob.targetedRemoteLocations && ijob.targetedRemoteLocations.length
        ? ijob.targetedRemoteLocations
        : [];
    return {
      id: ijob.id,
      title: ijob.title,
      experience_level: ijob.experienceLevel,
      sort_job: ijob.sortJob,
      alias: ijob.url,
      company_id: ijob.company.id,
      original_location: ijob.originalLocation,
      category_id: ijob.categoryID,
      body: ijob.body,
      body_summary: ijob.bodySummary,
      location: ijob.location,
      region_id: ijob.regionID,
      remote_status: ijob.remoteStatus,
      how_to_apply: ijob.howToApply,
      hybrid: ijob.isHybrid,
      targeted_remote_locations: targeted_remote_locations.map((target) => ({
        location_id: target.locationId,
        region_id: target.regionId,
      })),
    };
  });
};

/**
 * Options for /jobs filter
 */
export const companySizeOptions: DropdownSelectItem[] = [
  {
    name: '1-10 Employees',
    value: '1-10',
  },
  {
    name: '11-50 Employees',
    value: '11-50',
  },
  {
    name: '51-200 Employees',
    value: '51-200',
  },
  {
    name: '201-500 Employees',
    value: '201-500',
  },
  {
    name: '501-1000 Employees',
    value: '501-1000',
  },
  {
    name: '1000+ Employees',
    value: '1000',
  },
];

/**
 * Given aliases and any suffix params, build the path
 * @returns Url pathname
 */
export const formatJobFacetPath = (aliases: Array<any>, suffix: string) => {
  return (
    aliases
      .map((item: any) => {
        if (typeof item === 'string') return item;

        if (Array.isArray(item)) {
          const companySizeValues: string[] = companySizeOptions.map((x) => x.value);
          const isSizeFilter =
            item.length &&
            item.some((x: string) => {
              return companySizeValues.includes(x);
            });

          // Prepend size options with an identifier for SEO
          if (isSizeFilter) {
            const shouldTransformSizeItem = !item.includes('size');

            item = shouldTransformSizeItem
              ? ['size', ...item.sort()].join('/')
              : item.sort().join('/');
            return item;
          }

          return item.sort().join('/');
        }
      })
      .filter((item) => item !== '')
      .join('/') + suffix
  );
};

export const mapJobDtoToIJob = (job: JobDto, company: ICompany): IJob => {
  let locationName = job.location;
  if (!!job.geoLocations.length) {
    locationName = job.geoLocations[0].geoName;
  }
  return {
    __typename: 'Job',
    body: job.body,
    categoryID: job.categoryId ?? 0,
    company,
    experienceLevel: job.experienceBucket,
    howToApply: job.howToApply ?? '',
    id: job.id,
    isHybrid: job.isHybrid,
    isEasyApply: job.isEasyApply,
    location: locationName ?? '',
    bodySummary: job.bodySummary ?? '',
    originalLocation: '',
    regionID: job.regionId ?? getCurrentRegionID(),
    remoteStatus: job.isRemote ? RemoteOnly : '',
    sortJob: '', // Provide a default value if sort_job is null or undefined
    targetedRemoteLocations: [], // TODO implement mapping targetedRemoteLocations
    // @ts-ignore
    geoLocations: job.geoLocations,
    title: job.title,
    url: job.url,
  };
};
